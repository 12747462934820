<template>
  <div class="forgot-password-page">
    <!-- Le logo et le titre -->
    <md-empty-state md-icon="vpn_key" md-label="Mot de passe oublié ?">
      <!-- La zone de debug -->
      <pre v-if="isDebug" class="debug-log">
				errors :
				{{ errors }}

				form :
				{{ form }}
			</pre
      >

      <!-- Le champ email -->
      <md-field :class="{ 'md-invalid': errors.has('email') }">
        <label>E-mail</label>
        <md-input
          v-model="form.email"
          v-validate="'required|email'"
          name="email"
          type="email"
          @keyup.enter.native="forgotPassword"
        >
        </md-input>
        <span class="md-error" v-show="errors.has('email')">
          {{ errors.first("email") }}
        </span>
      </md-field>

      <!-- Le bouton d'envoi -->
      <md-button
        class="md-primary md-raised forgot-password-button"
        @click.native="forgotPassword"
      >
        Envoyer
      </md-button>

      <!-- Retour à la connexion -->
      <router-link to="/signin">
        Retour
      </router-link>
    </md-empty-state>
  </div>
</template>

<script>
import Login from "../../model/login";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        email: null,
      },
    };
  },
  methods: {
    forgotPassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Login.forgotPasswordAdmin(this.form.email)
            .then(() => {
              this.$toasted.global.AppSucces({
                message:
                  "Un email de changement de mot de passe vous à été envoyé",
              });

              this.$router.push("/signin-admin");
            })
            .catch((err) => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-page {
  height: 100vh;
  display: flex;
  justify-content: space-around;

  .forgot-password-button {
    width: 90%;
  }

  a {
    margin-top: 16px;
  }
}
</style>
